import React,{Component} from 'react';
import Helmet from 'react-helmet';
import {Steps,PageHeader,Button,message,Form,Input,Divider,/*Progress*/Result,Typography,Modal} from 'antd';
import {MailOutlined,CodeOutlined,LoadingOutlined,LockOutlined} from '@ant-design/icons';
import axios from 'axios';
import {nanoid} from 'nanoid';
import encrypt from '../../public/jsencrypt';
import AppContext from '../../public/app-context';

import css from './index.module.scss';

const {Consumer} = AppContext;
const {Step} = Steps;
const {Item} = Form;
const { Paragraph, Text } = Typography;

let timer = null;
export default class Forget extends Component{
    state = {
        steps:[
            {key:0,title:'验证邮箱'},
            {key:1,title:'重置密码'},
            {key:2,title:'完成'}
        ],
        currentStep:0,
        isCaptchaLoading:false,
        isChangePasswordLoading:false,
        emailCaptchaLoading:false,
        emailCaptchaDisabled:false,
        emailCaptchaCountdown:60,
        emailModalVisible:false,
        emailCaptchaText:'获取验证码',
        valueOfMail:'',
        valueOfCaptcha:'',//记录验证码信息，以便步骤2的时候再次进行校验
        valueOfPassword:'',
        valueOfPasswordConfirm:''
    }
    appContextValue = {};
    isInPromise = false;
    successInfo = {
        username:'',
        mail:'',
        isSuccess:false
    };
    doCaptcha = event => {
        //alert('执行获取验证码之后的下一步操作');
        this.setState({isCaptchaLoading:true});
        new Promise((resolve,reject) => {
            const hash = nanoid();
            const now = Math.floor(new Date().getTime()/1000);
            const param = {
                assign:encrypt.encrypt(JSON.stringify({
                    mail:this.state.valueOfMail,
                    captcha:this.state.valueOfCaptcha,
                    _time:now,
                    _timegap:this.appContextValue.timegap,
                    _hash:hash,
                    _onlykey:this.appContextValue.onlykey
                })),
                time:now,
                hash
            }
            axios.post('/api/admin/forget_fetch_captcha',param).then(response=>{
                const {data} = response;
                //alert(data);
                if(typeof data === 'object' && 'code' in data){
                    if(data.code === 0){
                        resolve();
                    }else{
                        reject(data.msg);
                    }
                }else{
                    reject('服务器忙，请稍候再试');
                }
            }).catch(err=>{
                reject('获取失败');
                console.warn(err);
            })
        }).then(()=>{
            //alert('then')
            clearInterval(timer);
            //message.success('验证码验证成功，请重置密码');
            this.setState({currentStep:1},()=>message.info('验证码验证成功，请重置密码'));
        }).catch(err=>{
            //alert('catch')
            message.error(err);
        }).finally(()=>{
            this.setState({isCaptchaLoading:false})
        });
    }
    emailWebLogin = mail => event => {
        const domain = mail.split('@')[1];
        if(!domain){
            message.error(`无法获取邮箱${mail}的域名，请手动登录`);
            return;
        }
        window.open(`https://mail.${domain}/?from=https://sheaneh.com`);
    };
    emailGetCaptcha = event => {
        clearInterval(timer);
        const {valueOfMail} = this.state;
        if(!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(valueOfMail)){
            message.warning('请输入正确的邮箱地址');
            return;
        }
        this.setState({
            emailCaptchaText:'获取中',
            emailCaptchaLoading:true,
            emailCaptchaDisabled:true
        });
        new Promise((resolve,reject) => {
            const hash = nanoid();
            const now = Math.floor(new Date().getTime()/1000);
            const param = {
                assign:encrypt.encrypt(JSON.stringify({
                    mail:valueOfMail,
                    _time:now,
                    _timegap:this.appContextValue.timegap,
                    _hash:hash,
                    _onlykey:this.appContextValue.onlykey
                })),
                time:now,
                hash
            }
            axios.post('/api/admin/forget_captcha',param).then(response => {
                const {data} = response;
                if(typeof data === 'object' && 'code' in data){
                    if(data.code === 0){
                        resolve();
                    }else{
                        reject(data.msg);
                    }
                }else{
                    reject('服务器忙，请稍候再试');
                }
            }).catch(err => {
                reject('获取失败');
                console.warn('无法获取验证码，请后端程序员检查',err);
            });
        }).then(()=>{
            if(!this.isInPromise){return;}
            this.setState({emailModalVisible:true});
            let {emailCaptchaCountdown} = this.state;
            timer = setInterval(()=>{
                emailCaptchaCountdown -= 1;
                //console.log(emailCaptchaCountdown);
                if(emailCaptchaCountdown <= 0){
                    emailCaptchaCountdown = 60;
                    this.setState({
                        emailCaptchaText:'获取验证码',
                        emailCaptchaLoading:false,
                        emailCaptchaDisabled:false,
                        emailCaptchaCountdown
                    });
                    clearInterval(timer);
                    return;
                }else{
                    this.setState({
                        emailCaptchaText:emailCaptchaCountdown + '秒后重新获取',
                        emailCaptchaLoading:false,
                        emailCaptchaDisabled:true
                    });
                }
            },1000);
        }).catch(err => {
            if(!this.isInPromise){return;}
            message.error(err);
            this.setState({
                emailCaptchaText:'获取验证码',
                emailCaptchaLoading:false,
                emailCaptchaDisabled:false
            })
        })
        //const param = Object.assign();
        //axios.post('/api/admin/forget_captcha',{});
        //alert('执行获取验证码操作');
    }
    handleEmailCaptchaCancel = event => {
        this.setState({
            emailModalVisible:false
        })
    }
    doChangePassword = event => {
        //alert('改密码');
        //console.log(this.state);
        new Promise((resolve,reject) => {
            const hash = nanoid();
            const now = Math.floor(new Date().getTime()/1000);
            const param = {
                assign:encrypt.encrypt(JSON.stringify({
                    mail:this.state.valueOfMail,
                    captcha:this.state.valueOfCaptcha,
                    password:this.state.valueOfPassword,
                    passwordconfirm:this.state.valueOfPasswordConfirm,
                    _time:now,
                    _timegap:this.appContextValue.timegap,
                    _hash:hash,
                    _onlykey:this.appContextValue.onlykey
                })),
                time:now,
                hash
            }
            axios.post('/api/admin/forget_fetch_password',param).then(response => {
                const {data} = response;
                if(typeof data === 'object' && 'code' in data){
                    if(data.code === 0){
                        resolve(data.data);
                    }else{
                        reject(data.msg);
                    }
                }else{
                    reject('服务器忙，请稍候再试');
                }
            }).catch(err => {
                reject('获取失败');
                console.warn(err);
            })
        }).then(data=>{
            this.successInfo.username = data.username;
            this.successInfo.mail = data.mail;
            this.successInfo.isSuccess = !!data.update_status;
            this.setState({currentStep:2},()=>message.info('密码重置成功，请返回登录'));
        }).catch(err=>{
            message.error(err);
        }).finally();
    }
    emailOnChange = event => {
        if(this.state.currentStep !== 0){
            return;
        }
        this.setState({
            valueOfMail:event.target.value
        });
    }
    captchaOnChange = event => {
        if(this.state.currentStep !== 0){
            return;
        }
        this.setState({
            valueOfCaptcha:event.target.value
        });
    }
    componentDidMount(){
        this.isInPromise = true;
    }
    componentWillUnmount(){
        clearInterval(timer);
        this.isInPromise = false;
        window.onbeforeunload = null;
    }
    render(){
        const {
            steps,
            currentStep,
            isCaptchaLoading,
            emailCaptchaLoading,
            emailCaptchaDisabled,
            emailCaptchaText,
            emailModalVisible,
            valueOfMail,
            valueOfCaptcha,
            isChangePasswordLoading,
            valueOfPassword,
            valueOfPasswordConfirm
        } = this.state;
        return (<>{/** vertical*/}
            <Consumer>
                {value => {
                    this.appContextValue = value;
                    return (<>
                        <div data-comment-sentinel-cover style={{display:'none'}}>
                            {/*`<!--(WYJS)>{${JSON.stringify(value)}}<!(/WYJS)-->`*/}
                        </div>
                    </>)
                }}
            </Consumer>
            <Helmet>
                <title>重置密码 - 仙娥小站</title>
            </Helmet>
            <Steps
                className={css['steps']}
                direction="horizontal" 
                responsive={true/*小屏下改为垂直模式*/}
                initial={0}
                status="process"
                type="default"
                size="small"
                current={currentStep}
            >
                {steps.map(item => (<Step key={item.key} className={css['step']} title={item.title}/>))}
            </Steps>
            {currentStep === 0 && <Form
                name="form-forget-captcha"
                className="form-forget-captcha"
                onFinish={this.doCaptcha}
            >
                <Item
                    name="mail"
                    rules={[
                        {required:true,message:'请输入邮箱'},
                        {pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message:'请输入正确的邮箱格式：xxx@xxx.com',validateTrigger:'onBlur'},
                        {max:50,message:'邮箱长度不得超过50个字符'}                            
                    ]}
                >
                    <Input 
                        prefix={<MailOutlined className="site-form-item-icon"/>}
                        placeholder="电子邮箱"
                        onChange={this.emailOnChange}
                        value={valueOfMail}
                    />
                </Item>
                <div className={[css['flex-between']].join(' ')}>
                    <Item
                        name="captchacode"
                        rules={[
                            {required:true,message:'请输入邮箱验证码'},
                            {pattern:/^[a-zA-Z0-9]+$/,message:'验证码不得含有特殊字符'}
                        ]}
                        style={{flex:'1',width:'100%'}}
                    >
                        <Input
                            prefix={<CodeOutlined className="site-form-item-icon" />}
                            placeholder="验证码"
                            value={valueOfCaptcha}
                            onChange={this.captchaOnChange}
                        />
                    </Item>
                    <Item style={{minWidth:/Trident/.test(navigator.userAgent)?'100px':'auto'}/*兼容IE*/}>
                        <Button icon={emailCaptchaLoading?(<LoadingOutlined />):(<></>)} name="getemailcode" type='default' disabled={emailCaptchaDisabled} onClick={this.emailGetCaptcha}>{emailCaptchaText}</Button>
                    </Item>
                </div>

                <Item>
                    <Button type="primary" htmlType="submit" loading={!!isCaptchaLoading} className={[css['captcha-button'],isCaptchaLoading?css['captcha-button-loading']:''].join(' ')}>提交</Button>{/**className="login-form-button" */}
                </Item>
            </Form>}
            {currentStep === 1 && <Form
                name="form-forget-password"
                className="form-forget-password"
                onFinish={this.doChangePassword}
            >
                <Item>
                    <span className={css['as-input-span-package']}>当前邮箱<span className={css['as-input-span']}>{valueOfMail}</span></span>
                </Item>
                <Item
                    name="password"
                    rules={[
                        {required:true,message:'请输入密码'},
                        {min:8,message:'密码长度不得低于8个字符'}
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="设置新密码"
                        value={valueOfPassword}
                        onChange={event => this.setState({valueOfPassword:event.target.value})}
                    />
                </Item>
                <Item
                    name="passwordconfirm"
                    rules={[
                        {required:true,message:'请再次确认输入密码'},
                        //{min:8,message:'密码长度不得低于8个字符'},
                        ({getFieldValue})=>({
                            validator(rule,value){
                                if(!value || getFieldValue('password') === value){
                                    return Promise.resolve()
                                }
                                return Promise.reject("两次密码输入不一致")
                            },
                            validateTrigger:'onBlur'
                        })
                        //{validator:'',validateTrigger:'onBlur'}
                    ]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="确认密码"
                        value={valueOfPasswordConfirm}
                        onChange={event => this.setState({valueOfPasswordConfirm:event.target.value})}
                    />
                </Item>
                <Item>
                    <Button type="primary" htmlType="submit" className={[css['captcha-button'],isChangePasswordLoading?css['captcha-button-loading']:''].join(' ')} loading={!!isChangePasswordLoading}>确认重置密码</Button>
                </Item>
            </Form>}
            {currentStep === 2 && <div style={{display:'flex',justifyContent:'center'}}>
                {this.successInfo.isSuccess?(<>
                    <Result
                        status="success"
                        title="密码重置成功"
                        subTitle={
                            `您的用户名是${this.successInfo.username}，电子邮箱是${this.successInfo.mail}，请牢记！`
                            
                                /**
                                <div>
                                    <div>请牢记您的用户信息</div>
                                    <div>
                                        <span>用户名</span>
                                        <span>{this.successInfo.username}</span>
                                    </div>
                                    <div>
                                        <span>邮箱</span>
                                        <span>{this.successInfo.mail}</span>
                                    </div>
                                </div>
                                 */
                            
                        }
                        extra={[
                            <Button type="primary" size="small" onClick={()=>this.props.history.push('/login')}>重新登录</Button>
                        ]}
                    />
                </>):(<>
                    <Result
                        status="error"
                        title="密码重置失败"
                        subTitle={`网络问题或服务器忙`}
                        extra={[
                            <Button size="small" type="primary" key="repair" onClick={()=>this.setState({currentStep:0})}>重新尝试</Button>,
                            <Button size="small" key="reload" onClick={()=>window.document.location.reload()}>强制刷新</Button>
                        ]}
                    />
                </>)}
            </div>}
            {[0,1,2].includes(currentStep) || <>
                <div key={-1} style={{display:'flex',justifyContent:'center'}}>
                    {/*<Progress type="circle" percent={100} width={80} status="exception" />*/}
                    <Result
                        status="error"
                        title="程序出错"
                        subTitle={`参数错误`}
                        extra={[
                            <Button size="small" key="repair" onClick={()=>this.setState({currentStep:0})}>尝试修复</Button>,
                            <Button size="small" type="primary" key="reload" onClick={()=>window.document.location.reload()}>强制刷新</Button>
                        ]}
                    />
                </div>
                {(()=>{message.error(`程序出错`)})()/**${currentStep} */}
            </>}
            <Divider/>
            <div className={css['extra-area']}>
                {currentStep === 1 && <Button type="default" className={css['extra-button']} onClick={()=>this.setState({currentStep:currentStep-1})}>上一步</Button>}
                <Button type="default" className={css['extra-button']} onClick={()=>{this.props.history.push('/login')}}>返回登录</Button>
                <Button type="default" className={css['extra-button']} onClick={()=>{this.props.history.push('/register')}}>返回注册</Button>
            </div>
            {/**
            <div className={css['container']}>
                <div className={css['pageheader-area']}>
                    <PageHeader
                        className="site-page-header"
                        onBack={() => null}
                        title="返回登录"
                        subTitle=""
                    />
                </div>
                <div className={css['wrap']}>
                    <Button>这是忘记密码的组件</Button>
                </div>
            </div>
             * 
             * 
             */}
            <Modal
                visible={emailModalVisible}
                title="邮箱验证码"
                onOk={this.handleEmailCaptchaCancel}
                onCancel={this.handleEmailCaptchaCancel}
                footer={[
                    (
                        <Button key="0" onClick={this.emailWebLogin(valueOfMail)}>登录邮箱查看</Button>
                    ),
                    (
                        <Button key="1" onClick={this.handleEmailCaptchaCancel} type="primary">返回输入</Button>
                    )
                ]}
            >
                <p>验证码将以邮件的方式发送至您的邮箱</p>
                <p style={{color:'red'}}>{valueOfMail}</p>
                <p>请注意查收</p>
            </Modal>
        </>)
    }
}