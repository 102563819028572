import axios from 'axios';
import {nanoid} from 'nanoid';
import encrypt from '../../public/jsencrypt';

//import PubSub from 'pubsub-js';

/*
console.log('do-register');
PubSub.subscribe('onlyhash',(_,data) => {
    hash = data;
    console.log('do-register接收到了',data);
});
*/



export default (values,appContextValue) => {
    return new Promise((resolve,reject)=>{
        if(!appContextValue){
            reject('浏览器端程序异常，请与管理员联系');
            return;
        }
        const now = Math.floor(new Date().getTime()/1000);
        const hash = nanoid();
        const assign = encrypt.encrypt(JSON.stringify(Object.assign({
            _time:now,
            _timegap:appContextValue.timegap,//服务器与客户端的时间差距，秒
            _hash:hash,
            _onlykey:appContextValue.onlykey
        },values)));
        if(assign === false){
            reject('内存溢出，请不要输入恶意代码');
            return;
        }
        const param = {
            assign,
            time:now,
            hash:hash,
        };
        //console.log('获取',param.assign);
        /*
        reject('终止');
        return;
        const {
            captchacode,
            email,
            password,
            passwordconfirm,
            username
        } = values;
        */
        axios.post('/api/admin/register',/*{
            captchacode,
            email,
            password,
            passwordconfirm,
            username
        }*/param).then(res=>{
            const {data} = res;
            if('code' in data){
                if(data.code === 0){
                    resolve();
                }else{
                    reject(data.msg);
                }
            }else{
                reject('服务器忙，请稍候再试');
            }
        }).catch(err=>{
            reject(`网络请求失败，返回信息：${err}`);
        })
    });
}