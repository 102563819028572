const ran = arr => arr[Math.floor(Math.random()*arr.length)];
const output = {
    get welcome(){
        return ran([
            '前世五百次回眸换来今生擦肩而过，能够遇到这么聪明伶俐的你真是三生有幸。亲，咱们来就做个游戏，如何？',
            '嘿！瞅啥呢！这么闲呀。咱们就来做个游戏呗',
            '哇！能够找到这里，你肯定是个天才，来就来了，那就做个游戏再走，如何呢',
            '哈哈，原来你也喜欢扒控制台呀，能够遇到这么优秀的你真是我前世修来的缘分，一起做个游戏好吗'
        ]);
    },
    get line(){
        return ran([
            '---------------------',
            '~~~~~~~~~~~~~~~~~~~~~',
            '-=-=-=-=-=-=-=-=-=-=-',
            '===================='
        ]);
    }
};
export default output;