var iehack = () => {
    if(!window.crypto){
        window.crypto = window.msCrypto;
    }
    var DOM = document.body || document.querySelector('body');
    if(typeof DOM === 'object'){
        if(!DOM.__proto__.remove){
            DOM.__proto__.remove = function(){
                DOM.parentNode.removeChild(DOM);
            }
        }
        /*
        if(!DOM.__prtot__.getRootNode){
            DOM.__prtot__.getRootNode = function(){
                return document.documentElement;
            }
        }
        */
    }
}

export default iehack;