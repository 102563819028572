import {consoleDeterOutput} from '../../config';

const consoleRewrite = () => {
    //return;
    try{
        const {log} = console;
        console.log = function(info){
            let deter = false;
            consoleDeterOutput.have.forEach(item => (""+info).includes(item) && (deter=true));
            deter || log(...arguments)
            return;
        }
    }catch(e){}
}

export default consoleRewrite;