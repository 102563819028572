import React,{Component} from 'react';
import Script from 'react-load-script';
import {/*NavLink,Redirect,Route,Switch*/} from 'react-router-dom';
import './index.css';
import css from './index.module.css';

//模块组件
import NoLogin from '../NoLogin';
import Manage from '../Manage';

//路由组件
//import Login from '../../pages/Login';
//import Register from '../../pages/Register';

import {/*Layout,*/message,Spin} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';

//引入配置信息
//import config from '../../config';


//引入一些额外的非react功能块
import debugForProduction from './debug-for-production';
import consoleRewrite from './console-rewrite';
import Slogan from './slogan';
import littleGame from './little-game';
import targetLink from './target-link';
import iehack from './iehack';
import comment from './comment';

import axios from 'axios';
import PubSub from 'pubsub-js';
import {nanoid} from 'nanoid';

import pub from '../../public/pub';
import AppContext from '../../public/app-context';

const {Provider} = AppContext;
//引入背景图
//import backImg from './back.jpg';

//const {Header,Footer,Content} = Layout;

//console.log("%c仙娥小站后台管理中心 | https://sheaneh.com", "color:#fff; background: linear-gradient(270deg, #986fee, #8695e6, #68b7dd, #18d7d3); padding: 8px 15px; border-radius: 0 15px 0 15px");
(function(){
    //console.log(React.useContext({}));
})()

export default class App extends Component{
    state = {
        isLogin:false,
        isLoading:true,
        directives:'auto',//manual
        isLoginSuccess:'',
        onlykey:'',
        hosttime:0,
    }
    _isMount = false
    changeLoginState = () => {
        axios.get('/api/getadminstate/all',{
            params:{
                hash:nanoid(),
            },
            headers:{
                Pragma:'no-cache'
            }
        }).then(res=>{
            const {data} = res;
            //console.log(data);
            if(data.code === 0){
                if(data.data){
                    if(data.data.isLogin !== this.state.isLoginSuccess && typeof this.state.isLoginSuccess === 'boolean'){
                        setTimeout(()=>{
                            document.location.reload();
                        },1000);
                        throw '状态异常，浏览器可能阻止了Cookie';
                    }
                    const {token,isLogin,username,lastAction,now,onlykey,hosttime} = data.data;
                    //this.setState({...data.data});
                    this.setState({isLogin,username,lastAction,now,onlykey,hosttime});
                    if(isLogin){
                        pub.set('token',token);
                    }else{
                        pub.del('token');
                    }
                    //console.log(this.state);
                    //PubSub.publish('onlyhash',hash);
                }
            }else{
                throw '服务器崩溃：' + (data.msg || '未知错误，程序可能无法运行');
            }
        },err=>{
            message.error('程序出错，请后端程序员提供正确且符合规范的API');
            console.warn(`${err}`);
        }).catch(err=>{
            message.error(err);
        }).finally(()=>{
            setTimeout(()=>{
                try{
                    this.setState({
                        isLoading:false
                    });
                }catch(e){
                    message.error('程序崩溃！请前端程序员认真检查每一个错误，排除故障后重新编译！');
                    alert('程序崩溃！请前端程序员认真检查每一个错误，排除故障后重新编译！');
                }
            },1);
        });
    }
    componentDidMount(){
        this._isMount = true;
        //必要的引擎加载
        debugForProduction();
        if(!/Trident|MSIE/.test(navigator.userAgent)){
            consoleRewrite();
        }else{
            iehack();
        }
        Slogan();
        littleGame();
        targetLink();
        comment();
        //重写安全策略
        (()=>{
            //let oMeta = document.querySelector('meta#o-meta-content-security-policy');
            //oMeta &&  oMeta.setAttribute('content',`default-src 'none'; script-src 'self'; connect-src 'self'; img-src 'self' data: blob:; style-src 'self' 'unsafe-inline'; font-src 'self';`);
        })()
        //后端接口获取状态
        this.changeLoginState();
        PubSub.subscribe('isLoginSuccess',(_,data)=>{//前端
            this.setState({isLoginSuccess:!!data});
        })
        PubSub.subscribe('isNoLoginAuto',(_,data)=>{//判断是自动还是手动
            //console.log('触动为'+data);
            this.setState({directives:data?'auto':'manaul'});
        });
        PubSub.subscribe('callLoginState',(_,data)=>{
            this.changeLoginState();
            //PubSub.publish('isChangeLoginState',false);
            //console.log(data);
            /*
            if(data){
                this.changeLoginState();
                alert('APP接收到了指令');
                PubSub.publish('isChangeLoginState',false);
            }
            */
            //alert('接收'+data);
            //this.setState({...data});
        });
        //用于在组件未挂载时临时接收的inputData放入状态，props里面接收inputdata 和receiveinputdata
        this.setState({inputData:this._tempInputData});
    }
    /*
    changeDirectives = toggle => event => {
        console.log('触动为'+toggle);
        this.setState({directives:toggle?'manual':'auto'});
    }
    */
    render(){
        const {/*nav,*/isLogin,isLoading,directives,inputData,onlykey,hosttime} = this.state;
        return (<>
            <div className={css.app}>
                <Provider value={{
                    onlykey,
                    hosttime,
                    timegap:Math.round(new Date().getTime()/1000 - hosttime)
                }}>
                    {/**
                     * 
                     * <Layout className={css.layout}>
                        <Header className={css.header}>
                            {nav.map(item=>(
                                <NavLink key={item.name} to={item.url}>{item.text}</NavLink>
                            ))}
                        </Header>
                        <Content className={css.content}>
                    * 
                    * 
                    */}
                    {/** style={{backgroundImage:'url('+backImg+')'}}  */}
                            {isLoading?(
                                <div className={css['inner-loading']}>
                                    <Spin indicator={(<LoadingOutlined style={{fontSize:'14px'}} spin/>)} tip=""></Spin>
                                    <div className={css['inner-loading-text']}>正在加载中……</div>
                                </div>
                            ):(
                                isLogin?(
                                    <>
                                        {/***<Switch> <Redirect to="/manage"></Redirect></Switch>*/}
                                        <Manage></Manage>
                                    </>
                                ):(
                                    <>
                                        {/*<Redirect to="/login"></Redirect>*/}
                                        <NoLogin directives={directives}></NoLogin>
                                    </>
                                )
                            )}
                            {/*
                            <Switch>
                                <Route path='/login' component={Login}></Route>
                                <Route path='/register' component={Register}></Route>
                                <Redirect to='/login'></Redirect>
                            </Switch>
                            */}
                            {/**8
                             * 
                             * 
                             * 
                             * 
                        </Content>
                        <Footer className={css.footer}>
                            <div>仙娥小站后台管理中心</div>
                            <div>Copyright © 2021 仙娥小站 [sheaneh.com] All Rights Reserved</div>
                        </Footer>
                    </Layout>
                            * 
                            */}
                    {/*
                    <Row>
                        <Col span={12}></Col>
                        <Col span={8}></Col>
                    </Row> */}
                    {/*
                    <div className={css.app}>
                        <div className={css.nav}>
                            <ul>
                                {
                                    nav.map(item=>(
                                        <li key={item.name}>
                                            <NavLink to={item.url}>{item.text}</NavLink>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className={css['show-area']}>
                            <Switch>
                                {/--*<Route exact={true} path='/' component={}></Route> *--/}
                                <Route path='/login' component={Login}></Route>
                                <Route path='/register' component={Register}></Route>
                                <Redirect to='/login'></Redirect>
                            </Switch>
                        </div>
                    </div>    
                    
                    
                    */}
                </Provider>
            </div>
            <Script attributes={{
                'data-wyjs2008':'',
                'type':'text/javascript',
                'language':'javascript',
                'wyjs-func':'none',
                'wyjs-obj':'none',
                'wyjs-vbs':'none',
            }} url="/admin/package/love/index.js?att="></Script>{/**情话多说一点，想我就多看一眼 */}
        </>);
    }
}

/***
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 */