import React,{Component,lazy,Suspense} from 'react';
import {Route,Redirect} from 'react-router-dom';
import {withRouter} from 'react-router';
//import {CSSTransition} from 'react-transition-group';

import './index.css';
import css from './index.module.css';

import Loading from '../Loading';

import {Layout, message} from 'antd';




//const {Header,Content,Footer,Silder} = Layout;
const Manage = lazy(()=>import('../../pages/Manage'));
class ManageComponent extends Component{
    getRouteInManage = () => {
        try{
            return /^\/manage/.test(this.props.history.location.pathname);
        }catch(e){
            message.warn('页面无法跳转，请手动在地址栏内输入/manage');
            return true;
        }
        //return /^\/manage/.test(this.props.history.pathname);
    }
    render(){
        //console.log(this.getRouteInManage());
        //console.log(this.props.history.loaction.pathname);
        return (
            <>
                <Suspense fallback={<div className={css.loading}><Loading msg="客官稍安勿躁……"/></div>}>   
                    <Route path="/manage" component={Manage}></Route>
                    {this.getRouteInManage()?(
                        <></>
                    ):(
                        <Redirect to="/manage"></Redirect>
                    )}
                </Suspense>
            </>
        );
    }
}

export default withRouter(ManageComponent);