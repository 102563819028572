import React,{Component} from 'react';
import Helmet from 'react-helmet';
import { Form,Input,Button,Space,Divider,message,Modal/*,Row,Col,Image*/ ,Select,Tag,Alert} from 'antd';
import {ExclamationCircleOutlined,LoadingOutlined,UserOutlined,LockOutlined,MailOutlined,CodeOutlined,MobileOutlined} from '@ant-design/icons';

import css from './index.module.css';

import Logo from '../../components/Logo';

import axios from 'axios';

import doRegister from './do-register';

import encrypt from '../../public/jsencrypt';

import {nanoid} from 'nanoid';
import AppContext from '../../public/app-context';
const {Consumer} = AppContext;

const {Item} = Form;
const {Option} = Select;
let timer = null;

export default class Register extends Component{
    constructor(props){
        super(props);
        const {valueOfMail} = this.state;
        if(valueOfMail !== ''){
            window.onbeforeunload = this.deWindowClose;
        }
    }
    state = {
        emailModalVisible:false,
        phoneRegisterModalVisible:false,
        emailCaptchaText:'获取验证码',
        emailCaptchaLoading:false,
        emailCaptchaDisabled:false,
        emailCaptchaCountdown:60,
        phoneCaptchaText:'获取验证码',
        phoneCaptchaLoading:false,
        phoneCaptchaDisabled:false,
        phoneCaptchaCountdown:60,
        valueOfMail:'',
        isRegisterLoading:false
    };
    nowValueOfMail = '';
    nowValueOfPhone = '';
    initInputData = {
        email:'',
        username:''
    }
    formDOM = React.createRef();
    mailDOM = React.createRef();
    usernameDOM = React.createRef();
    isInPromise = true;
    appContextValue = {};
    deWindowClose = event => {
        const {valueOfMail} = this.state;
        if(valueOfMail !== ''){
            //console.log(1);
            return '您输入的信息将无法被保存，请确认离开页面';
        }
        //console.log(2);
        return;
        
    };
    emailonchange = event => {
        //console.log(this.state.valueOfMail);
        //console.log(this.mailDOM.current.state.value);
        //console.log(event.target);
        this.setState({
            valueOfMail:this.mailDOM.current.state.value || event.target.value
        })
    }
    emailGetCaptcha = event => {
        const getValueOfMail = this.state.valueOfMail || this.nowValueOfMail;
        if(!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(getValueOfMail)){
            //console.log('邮箱地址',this.state.valueOfMail);
            message.warning('请输入正确的邮箱地址');
            return;
        }
        //if(this.state.)
        this.setState({
            //emailModalVisible:true,
            emailCaptchaText:'获取中',
            emailCaptchaLoading:true,
            emailCaptchaDisabled:true
        });
        new Promise((resolve,reject) => {
            /*
            setTimeout(()=>{
                if(Math.random() < 0.9){
                    resolve();
                }else{
                    reject('验证码获取失败');
                }
            },1500);
            */
            const hash = nanoid();
            const now = Math.floor(new Date().getTime()/1000);
            const param = {
                assign:encrypt.encrypt(JSON.stringify({
                    mail:this.state.valueOfMail,
                    _time:now,
                    _timegap:this.appContextValue.timegap,
                    _hash:hash,
                    _onlykey:this.appContextValue.onlykey
                })),
                //mail:this.state.valueOfMail,
                time:now,
                hash:hash,
            }
            axios.post('/api/admin/captcha',/*{
                mail:this.state.valueOfMail
            }*/param).then(response=>{
                const {data} = response;
                    if('code' in data){
                        if(data.code === 0){
                            resolve();
                        }else{
                            reject(data.msg);
                        }
                    }else{
                        reject('服务器忙，请稍候再试');
                    }
            }).catch(err=>{
                reject('获取失败');
                console.warn('无法获取验证码，请后端程序员检查',err);
            })
        }).then(resolve=>{
            if(!this.isInPromise){return;}
            this.setState({emailModalVisible:true});
            let {emailCaptchaCountdown} = this.state;
            timer = setInterval(()=>{
                emailCaptchaCountdown -= 1;
                if(emailCaptchaCountdown <= 0){
                    emailCaptchaCountdown = 60;
                    this.setState({
                        emailCaptchaText:'获取验证码',
                        emailCaptchaLoading:false,
                        emailCaptchaDisabled:false,
                        emailCaptchaCountdown
                    });
                    clearInterval(timer);
                    return;
                }else{
                    this.setState({
                        emailCaptchaText:emailCaptchaCountdown + '秒后重新获取',
                        emailCaptchaLoading:false,
                        emailCaptchaDisabled:true
                    });
                }
            },1000);
        }).catch(reject=>{
            if(!this.isInPromise){return;}
            message.error(reject);
            this.setState({
                emailCaptchaText:'获取验证码',
                emailCaptchaLoading:false,
                emailCaptchaDisabled:false
            });
        }).finally(()=>{
            if(!this.isInPromise){
                const msg = '亲爱的你想干嘛？？？';
                if(message && message.error && typeof message.error === "function"){
                    message.error(msg);
                }else{
                    alert(msg);
                }
            }
        });
    };
    phoneGetCaptcha = event => {
        //alert('获取手机验证码');
        message.error('网络错误，可能手机号注册功能未开启');
    }
    handleEmailCaptchaOk = event => {
        this.setState({
            emailModalVisible:false
        })
    };
    handleEmailCaptchaCancel = event => {
        this.setState({
            emailModalVisible:false
        })
    };
    emailWebLogin = mail => event => {
        const domain = mail.split('@')[1];
        if(!domain){
            message.error(`无法获取邮箱${mail}的域名，请手动登录`);
            return;
        }
        window.open(`https://mail.${domain}/?from=https://sheaneh.com`);
    };
    toPage = targetString => {
        switch(targetString){
            case 'login':
                return event => {
                    this.props.history.push('/login');
                }
            default:
                return event => {
                    message.error('未选择要去的页面')
                }
        }
    };
    formReset = event => {
        Modal.confirm({
            title:'请确认',
            icon:(<ExclamationCircleOutlined/>),
            content:'是否清空已填写的内容？',
            onOk:() => {
                this.clearModelInputValue();
                this.initInputData = {};
                this.formDOM.current.resetFields();
                console.log(this.props.history);
                this.props.history.replace('/register');
            },
            onCancel:() => {}
        });
        //const [form] = Form.useForm();
        //console.log(form);
    };
    toPhoneRegister = event => {
        /*
        Modal.error({
            title:'手机号注册',
            content:'抱歉，暂不支持手机号注册'
        });
        */
        this.setState({phoneRegisterModalVisible:true});
    };
    phoneRegister = event => {
        //alert('手机号注册');
        message.error('暂未开放手机号注册');
    }
    handleModelBind = (key,value) => value === undefined?'':this.props.handleInputData({[key]:value})[key]
    initModelInputValue = () => ({initialValues:{...this.props.getInputData()}})
    clearModelInputValue = () => this.props.handleInputData('@clear')
    /*{
        //console.log('这是ModelBind里面的event',event.target.value);
        //console.log('这是key,value',key,value);
        //this.props?.handleInputData({[key]:value});
        return this.props.handleInputData({[key]:value})[key];
        
        //this.setState({inputData:{[key]:event.target.value}},()=>{
        //    this.props.handleInputData(this.state.inputData);
        //});
        
        //this.props.handleInputData({[key]:event.target.value});
    }
    */
    register = values => {
        this.setState({isRegisterLoading:true});
        doRegister(values,this.appContextValue).then(()=>{
            message.success('注册成功，您现在可以登录啦');
            this.props.history.push('/login');
        }).catch(err=>{
            message.error(err);
        }).finally(()=>{
            this.setState({isRegisterLoading:false});
        });
    }
    componentDidMount(){
        /*
        setInterval(()=>{
            console.log('Register组件中定时器接收',this.props.inputData);
        },3000)
        */
        this.isInPromise = true;
        //this.props.history.listen(route => console.log(route));
        //console.log(this.props);
        //寻找props里面的默认input值，写入状态
        //console.log('Register加载时获取',this.props.getInputData());
        //this.setState({initInputData:this.props.getInputData()});
    };
    componentWillUnmount(){
        clearInterval(timer);
        this.isInPromise = false;
        window.onbeforeunload = null;
        //this.getEmailCaptchaPromise.then(()=>{}).catch(()=>{});
    };
    render(){
        const {
            emailModalVisible,
            phoneRegisterModalVisible,
            emailCaptchaText,
            emailCaptchaDisabled,
            emailCaptchaLoading,
            valueOfMail,
            isRegisterLoading,
            phoneCaptchaLoading,
            phoneCaptchaDisabled,
            phoneCaptchaText
        } = this.state;
        return (
            <>
                <Consumer>
                    {value => {
                        this.appContextValue = value;
                        return (<>
                            <div data-comment-sentinel-cover style={{display:'none'}}>
                                {/*`<!--(WYJS)>{${JSON.stringify(value)}}<!(/WYJS)-->`*/}
                            </div>
                        </>)
                    }}
                </Consumer>
                <Helmet>
                    <meta name="keywords" content="注册,仙娥小站,仙娥博客" />
                    <title>注册 - 仙娥小站</title>
                    {"Microsoft Internet Explorer" !== navigator.appName?(
                        <script language="vbscript" type="text/vbscript">
                            {`
    Dim Ua
    Ua = navigator.userAgent
    Sub Compat
        Dim Echo
        Echo = Msgbox("继续浏览本页，您需要升级您的IE浏览器到最新版本（IE11+）" + chr(13) + chr(13) + "点击【确定】升级您的浏览器" + chr(13) + "点击【取消】忽略此兼容性问题",17,"请升级您的浏览器")
        If Echo = 1 Then
            window.open("https://www.microsoft.com/zh-cn/edge")
        End If
    End Sub
    If inStr(Ua,"MSIE") <> 0 Then
        Call Compat
    End If 
                            `}
                        </script>
                    ):(
                        <script type="text/javascript">
                            /*预留javascript*/
                        </script>
                    )}
                </Helmet>
                <Logo></Logo>
                <Form ref={this.formDOM} onFinish={this.register} {...this.initModelInputValue()}>{/**initialValues={{email:initInputData.email,username:initInputData.username}} */}
                    <Item
                        name="email"
                        rules={[
                            {required:true,message:'请输入邮箱'},
                            {pattern:/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,message:'请输入正确的邮箱格式：xxx@xxx.com',validateTrigger:'onBlur'},
                            {max:50,message:'邮箱长度不得超过50个字符'}
                        ]}
                        normalize={value=>this.handleModelBind('email',value)}
                    >
                        <Input
                            prefix={<MailOutlined className="site-form-item-icon"/>}
                            placeholder="电子邮箱"
                            onBlur={this.emailonchange}
                            onChange={event=>this.nowValueOfMail = event.target.value}
                            ref={this.mailDOM}
                        />
                    </Item>
                    {/**
                     * 
                     *                     <Space style={{display:'flex',justifyContent:'space-between'}} size={0}>
                        
                    </Space>

                     * 
                     */}
                    <div className={[css['flex-between']].join(' ')}>
                        <Item
                            name="captchacode"
                            rules={[
                                {required:true,message:'请输入邮箱验证码'},
                                {pattern:/^[a-zA-Z0-9]+$/,message:'验证码不得含有特殊字符'}
                            ]}
                            style={{flex:'1',width:'100%'}}
                        >
                            <Input
                                prefix={<CodeOutlined className="site-form-item-icon" />}
                                placeholder="验证码"
                            />
                        </Item>
                        <Item style={{minWidth:/Trident/.test(navigator.userAgent)?'100px':'auto'}/*兼容IE*/}>
                            <Button icon={emailCaptchaLoading?(<LoadingOutlined />):(<></>)} name="getemailcode" type='default' disabled={emailCaptchaDisabled} onClick={this.emailGetCaptcha}>{emailCaptchaText}</Button>
                        </Item>
                    </div>
                    <Item
                        name="username"
                        rules={[
                            {required:true,message:'请输入用户名'},
                            //{pattern:/^[^0-9]/,message:'用户名不得以数字开头'},
                            {pattern:/^[^0-9]/,message:'用户名不得以数字开头'},
                            {pattern:/[a-zA-Z0-9]$/,message:'用户名只能以字母或数字结尾'},
                        ]}
                        normalize={value=>this.handleModelBind('username',value)}
                    >
                        <Input
                            prefix={<UserOutlined className="site-form-item-icon" />}
                            placeholder="用户名"
                            ref={this.usernameDOM}
                        />
                    </Item>
                    <Item
                        name="password"
                        rules={[
                            {required:true,message:'请输入密码'},
                            {min:8,message:'密码长度不得低于8个字符'}
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="密码"
                        />
                    </Item>
                    <Item
                        name="passwordconfirm"
                        rules={[
                            {required:true,message:'请再次确认输入密码'},
                            //{min:8,message:'密码长度不得低于8个字符'},
                            ({getFieldValue})=>({
                                validator(rule,value){
                                    if(!value || getFieldValue('password') === value){
                                        return Promise.resolve()
                                    }
                                    return Promise.reject("两次密码输入不一致")
                                },
                                validateTrigger:'onBlur'
                            })
                            //{validator:'',validateTrigger:'onBlur'}
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="确认密码"
                        />
                    </Item>
                    <Item>
                        <Button type="primary" htmlType="submit" className={[css['register-button'],isRegisterLoading?css['register-button-loading']:''].join(' ')} loading={!!isRegisterLoading}>注册</Button>
                    </Item>
                </Form>
                <Divider></Divider>
                <div className={css['extra-area']}>
                    <Button type="default" className={css['extra-button']} onClick={this.toPage('login')}>返回登录页面</Button>
                    <Button type="default" className={css['extra-button']} onClick={this.toPhoneRegister}>手机号注册</Button>
                    <Button type="default" className={css['extra-button']} onClick={this.formReset}>全部重写</Button>
                </div>
                <Modal
                    visible={emailModalVisible}
                    title="邮箱验证码"
                    onOk={this.handleEmailCaptchaOk}
                    onCancel={this.handleEmailCaptchaCancel}
                    footer={[
                        (
                            <Button key="0" onClick={this.emailWebLogin(valueOfMail)}>登录邮箱查看</Button>
                        ),
                        (
                            <Button key="1" onClick={this.handleEmailCaptchaCancel} type="primary">返回输入</Button>
                        )
                    ]}
                >
                    <p>验证码将以邮件的方式发送至您的邮箱</p>
                    <p style={{color:'red'}}>{/*sheaneh@sheaneh.com*/}{valueOfMail}</p>
                    <p>请注意查收</p>
                </Modal>
                <Modal
                    visible={phoneRegisterModalVisible}
                    title="手机号注册"
                    footer={false}
                    onCancel={() => this.setState({phoneRegisterModalVisible:false})}
                    style={{maxWidth:'350px'}}

                >
                    <Alert message="由于手机短信的API服务太过昂贵，本功能暂不可用，请使用邮箱注册" type="error" showIcon />
                    <Divider/>
                    <Form
                        onFinish={this.phoneRegister}
                    >
                    <Item
                        name="phone"
                        rules={[
                            {required:true,message:'请输入中国大陆的手机号'},
                            {pattern:/^[1]{1}[0-9]{10}$/,message:'请输入正确的手机号',validateTrigger:'onBlur'},
                            {max:11,message:'请输入正确的手机号'}
                        ]}
                    >
                        <Input
                            disabled
                            prefix={<>
                                <MobileOutlined className="site-form-item-icon"/>
                                <span style={{paddingLeft:'6px'}}></span>
                                <Tag>+86</Tag>
                            </>}
                            placeholder="手机号(仅限中国大陆)"
                            onChange={event=>this.nowValueOfPhone = event.target.value}
                        />
                    </Item>
                        <div className={[css['flex-between']].join(' ')}>
                            <Item
                                name="captchacode"
                                rules={[
                                    {required:true,message:'请输入邮箱验证码'},
                                    {pattern:/^[a-zA-Z0-9]+$/,message:'验证码不得含有特殊字符'}
                                ]}
                                style={{flex:'1',width:'100%'}}
                            >
                                <Input
                                    disabled
                                    prefix={<CodeOutlined className="site-form-item-icon" />}
                                    placeholder="验证码"
                                />
                            </Item>
                            <Item style={{minWidth:/Trident/.test(navigator.userAgent)?'100px':'auto'}/*兼容IE*/}>
                                <Button 
                                    icon={phoneCaptchaLoading?(<LoadingOutlined />):(<></>)} 
                                    name="getemailcode" 
                                    type='primary' 
                                    disabled={true/*phoneCaptchaDisabled*/} 
                                    onClick={this.phoneGetCaptcha}
                                >{phoneCaptchaText}</Button>
                            </Item>
                        </div>
                        <Item>
                            <Button disabled type="primary" htmlType="submit" className={css['register-button']}>注册并完善个人信息</Button>
                        </Item>
                    </Form>
                </Modal>
            </>
        )
    };
}