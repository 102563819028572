import axios from 'axios';
//import CryptoJS from 'crypto-js';
//import JSEncrypt from 'jsencrypt';
import {nanoid} from 'nanoid';

import encrypt from '../../public/jsencrypt';

export default (values,appContextValue) => {
    if(values.remember){
        //values.username;
        localStorage.setItem('local_username',''+values.username);
    }else{
        localStorage.removeItem('local_username');
    }
    //console.log(encrypt.encrypt(JSON.stringify(param)));
    //return;
    return new Promise((resolve,reject)=>{
        if(!appContextValue){
            reject('浏览器端程序异常，请与管理员联系');
        }
        const now = Math.floor(new Date().getTime()/1000);
        const hash = nanoid();
        const param = {
            assign:encrypt.encrypt(JSON.stringify(Object.assign({
                _time:now,
                _timegap:appContextValue.timegap,//服务器与客户端的时间差距，秒
                _hash:hash,
                _onlykey:appContextValue.onlykey
            },values))),
            time:now,
            hash:hash,
            //onlykey:appContextValue.onlykey
        };
    
        if(typeof appContextValue.timegap !== 'number' || typeof appContextValue.onlykey !== 'string'){
            reject('浏览器端验证器异常，请与管理员联系');
            return;
        }
        //const {username,password} = values;
        axios.post(`/api/admin/login?_h=${nanoid()}`,/*{
            username,
            password
        }*/param).then(res => {
            const {data} = res;
            if('code' in data){
                if(data.code === 0){
                    if(!data.data || !data.data.token){
                        reject('token值无效，请后端程序员检查');
                    }else{
                        resolve(data.data);
                    }
                }else{
                    reject(data.msg);
                }
            }else{
                reject('服务器忙，请稍候再试');
            }
        }).catch(err=>{
            reject(`网络请求失败，返回信息：${err}`);
        });
    });
}


