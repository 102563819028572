import React,{Component} from 'react';

import {Spin,Skeleton} from 'antd';

import {Loading3QuartersOutlined} from '@ant-design/icons'

//import {  } from 'lodash';
import {Three} from './svg-component/index.jsx';

import css from './index.module.css';

export default class Loading extends Component{
    state = {
        msg:'加载中，请稍候',
        diyicon:false,
        isSkeleton:false,
        skeletonConfig:{},
    }
    /*
    ComponentLoading = () => {
        const svgs = [Ball,Bars,Circles,Grid,Hearts,Rings,SpinCircles,Tail,Three];
        return svgs[Math.floor(Math.random() * svgs.length)]
    }
    */
    componentDidMount(){
        if(this?.props?.msg){
            this.setState({msg:this.props.msg});
        }
        if(this?.props?.mode){
            if(this.props.mode === 'skeleton'){
                let {skeletonConfig} = this.state;
                if(typeof this.props.config === 'object'){
                    skeletonConfig = this.props.config;
                }
                this.setState({isSkeleton:true,skeletonConfig})
            }
        }
        if(this?.props?.diyicon){
            this.setState({diyicon:this.props.diyicon});
        }
    }
    render(){
        const {msg,isSkeleton,skeletonConfig,diyicon} = this.state;
        return (
            <div className={css.container}>
                {isSkeleton?(
                    <>
                        <Skeleton active {...skeletonConfig} />
                    </>
                ):(
                    <>
                        <div className={css.icon} style={{cursor:'wait'}}>
                            {!diyicon?(
                                <Spin indicator={(<Loading3QuartersOutlined style={{ fontSize: 24 }} spin />)} />
                            ):(
                                <Three/>
                            )}
                            
                        </div>
                        <div className={css.msg} style={{cursor:'wait'}}>
                            <span>{msg}</span>
                        </div>
                    </>
                )}
            </div>
        )
    }
}