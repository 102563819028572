import 'core-js';
import 'mutation-observer';
//import 'react-app-polyfill/ie9';
//import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Root from './Root';
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(React.createElement(Root),document.querySelector('#root'));

//reportWebVitals();