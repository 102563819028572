import React,{Component,lazy,Suspense} from 'react';
import Helmet from 'react-helmet';
import {NavLink} from 'react-router-dom';
import { Form,Input,Button,Checkbox,Divider,message,Modal,Tooltip/*,Row,Col,Image*/ } from 'antd';
import {UserOutlined,LockOutlined} from '@ant-design/icons';

import css from './index.module.css';

import Logo from '../../components/Logo';

import doLogin from './do-login';

import PubSub from 'pubsub-js';

import AppContext from '../../public/app-context';

import QRCode from '../../components/QRCode';

import pub from '../../public/pub';

//const QRCode = lazy(()=>import('../../components/QRCode'));
const {Consumer} = AppContext;
//console.log('Login',Consumer);
//import axios from 'axios';


//import CryptoJS from 'crypto-js';

//console.log(CryptoJS);

const {Item} = Form;


export default class Login extends Component{
    state = {
        isLoginLoading:false,
        qrcodeLoginModalVisible:false
    }
    appContextValue = {};
    login = values => {
        if(!this.appContextValue.onlykey || !this.appContextValue.hosttime || typeof this.appContextValue.timegap !== 'number'){
            //message.error('程序初始化错误，请后端程序员认真、仔细地检查，排除故障！');
            message.warning('无效的参数，请稍候重试，或尝试刷新页面。如有BUG,可以向技术人员反馈');
            //console.log(this.appContextValue);
            return;
        }
        
        this.setState({
            isLoginLoading:true
        });
        //console.log(values);
        //alert('登录成功');
        doLogin(values,this.appContextValue).then(data=>{
            pub.set('token',data.token);
            message.success(`${data.username?`欢迎${data.username}`:'登录成功'}，即将进入后台管理系统`);
            /*
            setTimeout(()=>{
                this.props.history.push('/manage');
            },1000)
            */
            /*
            axios.get('/api/getadminstate/all').then(res=>{
                PubSub.publish('adminstate',res.data.data);
            }).catch(()=>{
                alert('获取失败')
            });
            */
            PubSub.publish('isLoginSuccess',true);
            PubSub.publish('callLoginState',new Date().getTime());
        }).catch(err=>{
            message.error(err);
        }).finally(()=>{
            this.setState({
                isLoginLoading:false
            })
        });
        //const {username,password} = values;
        //message.success(`登录信息，用户名${username}，密码${password}`);
    }
    toPage = targetString => {
        switch(targetString){
            case 'register':
                return event => {
                    //console.log(event,this.props);
                    this.props.history.push('/register');
                }
            case 'login':
                return event => {
                    this.props.history.push('/login');
                }
            default:
                return event => {
                    message.error('未选择要去的页面');
                }
        }
    }
    loginWithCaptcha = event => {
        Modal.error({
            title:'验证码登录',
            content:'验证码接口错误，请重试'
        });
    }
    loginWithQRCode = event => {
        /*
        Modal.error({
            title:'二维码登录',
            content:'暂时不支持二维码登录'
        });
        */
       this.setState({qrcodeLoginModalVisible:true});
    }
    a = 0;
    handleModelBind = (key,value) => value === undefined?'':this.props.handleInputData({[key]:value})[key]
    initModelInputValue = () => {
        const getData = this.props.getInputData();
        return {
            initialValues:{
                remember:!!localStorage.getItem('local_username'),
                username:'' + (getData.username || getData.email || (localStorage.getItem('local_username') || ''))
            }
        }
    }//({initialValues:{...this.props.getInputData()}})

    componentDidMount(){
        /*
        if(this.a==0){
            this.props.flexReserve(true);
            this.a = 1;
        }
        */
        //console.log(this.props.flexReserve);
    }
    /**                
 */
    render(){
        const {isLoginLoading,qrcodeLoginModalVisible} = this.state;
        return (
            <>
                <Consumer>
                    {value => {
                        this.appContextValue = value;
                        return (<>
                            <div data-comment-sentinel-cover style={{display:'none'}}>
                                {/*`<!--(WYJS)>{${JSON.stringify(value)}}<!(/WYJS)-->`*/}
                            </div>
                        </>)
                    }}
                </Consumer>
                <Helmet>
                    <title>登录 - 仙娥小站</title>
                </Helmet>
                <Logo></Logo>
                {/** initialValues={{remember:false}}*/}
                <Form
                    name="normal_login"
                    className="login-form"
                    {...this.initModelInputValue()}
                    onFinish={this.login}
                >
                    <Item
                        name="username"
                        rules={[{required:true,message:'请输入用户名'}]}
                    >
                        <Input 
                            prefix={<UserOutlined className="site-form-item-icon"/>}
                            placeholder="用户名/电子邮箱" 
                        />
                    </Item>
                    <Item
                        name="password"
                        rules={[{required:true,message:'请输入密码'}]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="密码"
                        />
                    </Item>
                    <Item className={css['login-remember-area']}>
                        <div className={[css['forchild-noselect'],'space-between'].join(' ')}>
                            <Item 
                                name="remember"
                                valuePropName="checked"
                                noStyle
                            >
                                <Checkbox>
                                    <Tooltip title="请不要在公用电脑勾选此项" color="#ffb6c1cc" overlayInnerStyle={{color:'#30353dcc',border:'1px solid #ffb6c1'}} destroyTooltipOnHide={false} mouseEnterDelay={1}>
                                        记住用户名
                                    </Tooltip>
                                </Checkbox>
                            </Item>
                            {/*<a className="login-form-forget" href="baidu.com">找回密码</a>*/}
                            <Tooltip title="重置密码" color="#ffb6c1cc" overlayInnerStyle={{color:'#30353dcc',border:'1px solid #ffb6c1'}} destroyTooltipOnHide={false} mouseEnterDelay={0.5}>{/**#30353d */}
                                <NavLink className="login-form-forget" to="/forget">忘记密码？</NavLink>
                            </Tooltip>
                        </div>
                    </Item>
                    <Item>
                        <Button type="primary" htmlType="submit" loading={!!isLoginLoading} className={[css['login-button'],isLoginLoading?css['login-button-loading']:''].join(' ')}>登录</Button>{/**className="login-form-button" */}
                    </Item>
                </Form>
                <Divider></Divider>
                <div className={css['extra-area']}>
                    <Button type="default" className={css['extra-button']} onClick={this.toPage('register')}>注册账号</Button>
                    {/*<Button type="default" className={css['extra-button']} onClick={this.loginWithCaptcha}>验证码登录</Button>*/}
                    <Button type="default" className={css['extra-button']} onClick={this.loginWithQRCode}>二维码登录</Button>
                </div>
                <Modal
                    visible={qrcodeLoginModalVisible}
                    title="二维码登录"
                    onCancel={()=>this.setState({qrcodeLoginModalVisible:false})}
                    footer={false}
                    style={{maxWidth:'200px'}}
                >
                    <div className={css['qrcode-package']}>
                        <QRCode
                            value={(''+document.location.href)}
                            size={138}
                        />
                    </div>
                </Modal>
            </>
        )
    }
}