import React,{Component,createRef} from 'react';
import QRCode from 'qrcode.react';
import {nanoid} from 'nanoid';

//console.log('qrcode',QRCode);
export default class QRCodeComponent extends Component{
    state = {
        value:'',
        size:0,
    };
    codeDOM = createRef();
    doUpdate = props => {
        const value = (''+props.value) || '无内容';
        const size = (typeof props.size === 'number' && props.size > 0)?props.size:128;
        if(value === this.state.value && size === this.state.size){
            return;
        }
        this.setState({value,size});
    }
    componentDidMount(){
        this.doUpdate(this.props);
    }
    componentDidUpdate(newProps){
        this.doUpdate(newProps);
    }
    render(){
        const {value,size} = this.state;
        return (<>
            <QRCode
                value={value}
                renderAs="canvas"
                size={size}
                bgColor="#FFFFFF"
                fgColor="#5C434A"
                level="Q"
                includeMargin={false}
                imageSettings={{
                    src:'https://sheaneh.com/avatar.png',
                    height:30,
                    width:30
                    //src:'https://',
                    //x:'',
                    //y:'',
                    //height:'',
                    //width:'',
                    //excavate:false
                }}
            />
        </>)
    }
}