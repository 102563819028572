import { getKeyThenIncreaseKey } from 'antd/lib/message';
import React,{Component} from 'react';
import css from './index.module.css';

export default class Logo extends Component{
    state = {
        title:'仙娥小站',
        desc:'后台管理系统',
        logo:{
            w:80,
            h:80
        },
        isText:{
            title:true,
            desc:true
        },
        style:{}
    }
    getOnlyHash = () => {
        return (Math.floor(Math.random()*16777215)).toString(16) + '_' + (new Date().getTime()%65535).toString(16);
    }
    getComponentBaseValue = (props,state) => {
        state = state || this.state;
        let {w,h} = state.logo;
        let {title,desc} = state.isText;
        let style = {};
        w = props.width || w;
        h = props.height || h;
        title = typeof props.showtitle === 'boolean'?props.showtitle:title;
        desc = typeof props.showdesc === 'boolean'?props.showdesc:desc;
        style = typeof props.innerStyle === 'object'?props.innerStyle:style;
        return {
            logo:{w,h},
            isText:{title:!!title,desc:!!desc},
            style
        };
    }
    componentDidMount(){
        this.setState({...this.getComponentBaseValue(this.props)});
    }
    static getDerivedStateFromProps(nextProps,prevState){
        //console.log(prevState);
        let {title,desc} = prevState.isText;
        title = typeof nextProps.showtitle === 'boolean'?nextProps.showtitle:title;
        desc = typeof nextProps.showdesc === 'boolean'?nextProps.showdesc:desc;
        return {isText:{
            title,desc
        }};
        //return this.getComponentBaseValue(nextProps,prevState);
    }
    /*
    componentDidUpdate(newProps,newState){
        console.log(newProps,newState);
    }
    */
    render(){
        const {title,desc,logo,isText,style} = this.state;
        return (
            <div className={css['logo-area']} style={style}>
                <svg className={css['logo-svg']} id={`sheaneh_logo_${this.getOnlyHash()}`} data-name="sheaneh_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192" width={logo.w} height={logo.h}>
                    <path d="M7195.7,7130.8c-15,23.7-43,34.5-69.1,40.7-6.3,1.5-3.7,11.2,2.6,9.7,28.8-6.9,58.6-19.3,75.1-45.4,3.5-5.4-5.2-10.5-8.6-5Z" transform="translate(-7103.5 -7103.5)"/>
                    <rect width="192" height="192" rx="12" ry="12" style={{fill:'none'}}/>
                    <path d="M7195.2,7135.5c16.5,26,46.3,38.4,75.1,45.3,6.2,1.5,8.9-8.1,2.6-9.6-26.1-6.3-54.1-17-69.1-40.8-3.4-5.4-12.1-.4-8.6,5.1Z" transform="translate(-7103.5 -7103.5)"/><path d="M7208,7174v91.5c0,6.4,10,6.4,10,0V7174c0-6.4-10-6.4-10,0Z" transform="translate(-7103.5 -7103.5)"/>
                    <path d="M7172.6,7205.5l-.2,27.6c0,8-1.2,16.9,2,24.4,6.3,15.2,24.1,15.5,37.9,13.9,6.3-.7,6.4-10.7,0-10-11.5,1.3-28.8,2.4-29.9-13.2-1-14.1.1-28.6.2-42.7,0-6.4-10-6.4-10,0Z" transform="translate(-7103.5 -7103.5)"/>
                    <path d="M7242.7,7205.5c.1,14.1,1.2,28.6.2,42.7-1.1,15.6-18.4,14.5-29.9,13.2-6.4-.7-6.3,9.3,0,10,13.8,1.6,31.6,1.3,37.9-13.9,3.2-7.5,2.1-16.5,2-24.4l-.2-27.6c0-6.4-10-6.4-10,0Z" transform="translate(-7103.5 -7103.5)"/>
                    <path d="M7164.3,7259c-3.8.2-8.7,1.3-10.1-3.1a21.4,21.4,0,0,1-.4-6.2c-.1-9-.1-17.9-.1-26.8,0-17.6.1-35.1.3-52.7,0-6.4-10-6.4-10,0q-.3,32.4-.3,64.6c0,7.7-1.5,17,1.1,24.5,3.1,9,10.8,10.2,19.5,9.7,6.3-.4,6.4-10.4,0-10Z" transform="translate(-7103.5 -7103.5)"/>
                    <path d="M7212.7,7169.7c.7-3.7,4.6-6.1,8.4-6.6a7.1,7.1,0,0,1,4.3.5,6.7,6.7,0,0,1,2.2,2.5c2,4,.7,8.9-1.3,13-2.9,6.4-7.4,12.3-13.6,15.6h0c-6-3.3-10.3-9.2-13.2-15.6-1.9-4-3.2-8.9-1.2-12.9a5.4,5.4,0,0,1,2.2-2.5,6.4,6.4,0,0,1,4.1-.6c3.7.5,7.5,3,8.2,6.7" transform="translate(-7103.5 -7103.5)" style={{fill:'#ed1e79'}}/>
                    <path d="M7177.1,7196.4c.7-3.7,4.6-6.2,8.4-6.6a6.7,6.7,0,0,1,4.2.5,5.9,5.9,0,0,1,2.2,2.5c2.1,4,.7,8.9-1.2,13-3,6.4-7.4,12.3-13.6,15.6h0c-6-3.3-10.4-9.2-13.2-15.6-1.9-4-3.2-8.9-1.2-12.9a5.4,5.4,0,0,1,2.2-2.5,6.4,6.4,0,0,1,4.1-.6c3.7.5,7.5,2.9,8.2,6.7" transform="translate(-7103.5 -7103.5)" style={{fill:'#ed1e79'}}/>
                    <path d="M7247.7,7193.6c.7-3.8,4.6-6.2,8.4-6.7a7,7,0,0,1,4.3.6,5.4,5.4,0,0,1,2.2,2.5c2,4,.7,8.9-1.2,13-3,6.3-7.5,12.2-13.7,15.5h0c-6-3.3-10.3-9.2-13.2-15.5-1.8-4.1-3.1-9-1.2-13a5.3,5.3,0,0,1,6.4-3c3.6.5,7.4,2.9,8.1,6.6" transform="translate(-7103.5 -7103.5)" style={{fill:'#ed1e79'}}/>
                </svg>
                {(isText.title || isText.desc)?(
                    <div className={css['logo-text']}>
                        {isText.title?(
                            <h1>{title}</h1>
                        ):(
                            <></>
                        )}
                        {isText.desc?(
                            <p>{desc}</p>
                        ):(
                            <></>
                        )}
                    </div>
                ):(
                    <></>
                )}
                
            </div>
        )
    }
}