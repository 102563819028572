import axios from 'axios';
import db from './little-game-database';

const littleGame = () => {
    /*
    let t0 =setInterval(()=>{
        let time = new Date().getTime();
        debugger;
        if(new Date().getTime() - time > 500){
            clearInterval(t0);
            document.documentElement.innerHTML = '<center>关闭控制台以继续浏览</center>';
        }
    },1000)
    */
    /*
    function loop() {
        window.requestAnimationFrame(function() {
            debugger;
            loop();
        })
    }
    loop();
    */
    /*
    let t=0;
    let devt = new Date();
    devt.toString = function(){
        if(t==0){t++;return;}
        document.documentElement.innerHTML = '<center>关闭控制台以继续浏览</center>';
        //alert('关闭控制台以继续浏览');
    }
    console.info('',devt);
    */
    /*
    (function () {
        var re = /x/;
        var i = 0;
        console.log(re);
          
        re.toString = function () {
        alert("请关闭控制台");
        return '第 ' + (++i) + ' 次打开控制台';
        };
    })();
    */
    /*
    let dom = document.createElement('div');
    Object.defineProperty(dom,'id',{
        get:function(){
            alert('开');
        }
    });
    console.log(dom);
    */
    /*
    var observerConsole = {
        openCallback: function(){
            console.log('控制台打开了');
            try {
                window.open("about:blank", "_self")
            } catch(e) {
                var btn = document.createElement("button");
                btn.onclick = function() {
                    window.open("about:blank", "_self")
                }
                btn.click()
            }
        },
        observer: function(){
            //这里使用dom元素，在打开控制台时才会计算id
            var dom = document.createElement("div"), that = this;
            Object.defineProperty(dom, "id", {
                get: function(){
                    that.openCallback()
                }
            })
            //ie不支持console.table
            //console.info(dom);
            console.log(dom);
        },
        observerF: function(){
            var obj = Object.create(null), t = Date.now(), that = this;
            Object.defineProperty(obj, "a", {
                get: function() {
                    if(Date.now() - t > 100){
                        that.openCallback()
                    }
                }
            })
            setInterval(function(){
                t = Date.now();
                (function(){})["constructor"]("debugger")();//debugger;
                console.log(obj.a);
            }, 200)
        },
        init: function(){
            var t = window.navigator.userAgent.toLowerCase();
            t.indexOf("firefox") >= 0 ? this.observerF() : this.observer();
        }
    }
    observerConsole.init();
    //ConsoleManager.init()
    //————————————————
    //版权声明：本文为CSDN博主「hfhan_872914334」的原创文章，遵循CC 4.0 BY-SA版权协议，转载请附上原文出处链接及本声明。
    //原文链接：https://blog.csdn.net/hf872914334/article/details/103794271
    */
    /*
    (()=>{
        let timelimit = 50;
        let open = false;
        setInterval(()=>{
            var startTime = new Date().getTime();

            //window.eval('debugger;');
            if(new Date().getTime() - startTime > timelimit){
                open = true;
            }else{
                open = false;
            }
            console.log(open);
        },500);
    })();
    */
    /*
    function doCheck(a){
        (function(){}['constructor']('debugger')());
        doCheck(++a);
    }
    try{doCheck(0)}catch(e){console.log(e)}
    */
    //var element = new Image();
    /*
    var element = document.createElement('div');
    element.id="usuuu";
    Object.defineProperty(element, "id", {
        get: function () {
            console.log("debugger start")
            //debugger;
            return 'aaaaaa';
        },
    });

    requestAnimationFrame(function check() {
        console.log(element);
    });
    */
    /*
    let open = false;
    let c=0;
    let openonce = false;
    setInterval(()=>{
        open = false;
        let devt = /./;new Date();
        devt.toString = function(){
            if(c<10){c++;return;}
            open = true;
        }
        console.info('',devt);
        //console.clear();
    },1000);
    setInterval(()=>{
        if(open){
            if(openonce){
                if(open == false){
                    alert('控制台已关闭');
                }
                return;
            }
            openonce = true;
            document.documentElement.innerHTML = '<center>关闭控制台以继续浏览</center>'
        }
    },100);
    */
    /*
    var ConsoleManager={
        onOpen(){
            alert("Console is opened")
        },
        onClose(){
            alert("Console is closed")
        },
        init(){
            var self = this;
            var x = document.createElement('div');
            //x.setAttribute('id',"aaa");
            var isOpening = false,isOpened=false;
            Object.defineProperty(x, 'id', {
                get(){
                    if(!isOpening){
                        self.onOpen();
                        isOpening=true;
                    }
                    isOpened=true;
                    return '';
                }
            });
            setInterval(function(){
                isOpened=false;
                console.info(x);
                //console.clear();
                if(!isOpened && isOpening){
                    self.onClose();
                    isOpening=false;
                }
            },200)
        }
    }
    
    ConsoleManager.onOpen = function(){
        alert("Console is opened!!!!!")
    }
    ConsoleManager.onClose = function(){
        alert("Console is closed!!!!!")
    }
    ConsoleManager.init();
    */
    
    
    setTimeout(()=>{
        console.log(
            `%c${db.welcome}%c\n\n%ca.小测试\nb.剧本杀\nc.棋牌类\nd.真心话大冒险\nz.面试题\n%c(回复对应字母开始游戏)`,
            'padding:8px;border-radius:3px;background-color:#f9efe9;line-height:1.8;font-size:14px;font-family:\'xiane\'',
            'color:gray;font-weight:bold;font-family:\'xiane\'',
            'font-size:12px;font-weight:normal;line-height:1.5;font-family:\'xiane\'',
            'color:lightgray;font-family:\'xiane\''
        );
        let select = (opt) => {
            /*
            switch(opt){
                case 'a':
                    console.log('当前A选项');
                    break;
                case 'b':
                    console.log('当前B选项');
                    break;
                case 'c':
                    console.log('当前C选项');
                    break;
                case 'd':
                    console.log('当前D选项');
                    break;
                default:
                    console.log('错误');
            }
            */
            axios.get('/api/article/allwithdesc').then(response => {
                let {data} = response;
                if(data.code === 0){
                    console.log(data.data[0].title);
                }else{
                    console.log(`%c错误:${data.code}`,'background-color:yellow');
                }
            },error => {
                console.log('很抱歉，网络错误，游戏无法继续');
            });
            return db.line;//`--------------------------------------`;
        }
        const put = ['a','b','c','d','z'];
        for(/*i=0;i<put.length;i++*/let k in put){
            (()=>{
                let item = put[k];
                if(!(item in window)){
                    Object.defineProperty(window,item,{get:() => select(item)});
                }
            })();
        }
        //Object.defineProperty(window,'b',{get:function(){return select('b');}})
        //Object.defineProperty(window,'c',{get:function(){return select('c');}})
        //Object.defineProperty(window,'d',{get:function(){return select('d');}})
    },1800);
}

export default littleGame;