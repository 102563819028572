const state = {};
export default {
    set(key,value){
        state[key] = value;
        return value;
    },
    get(key){
        return state[key] || null;
    },
    del(key){
        delete state[key];
        return null;
    }
}