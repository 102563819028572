import React, {
    Component /*,lazy,Suspense*/,
    lazy,
    Suspense,
    /*createRef*/ Fragment,
} from 'react';
import { NavLink, Route, Switch, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router';
//import {TransitionGroup,CSSTransition} from 'react-transition-group';
//import ReactCSSTransitionGroup  from 'react-addons-css-transition-group';

import Login from '../../pages/Login';
import Register from '../../pages/Register';
import Forget from '../../pages/Forget';
import Logo from '../Logo';

import './index.css';
import css from './index.module.css';

import { Layout, Menu, /*message,Row,Col,Image*/ message } from 'antd';
import { KeyOutlined, UserAddOutlined, HomeOutlined } from '@ant-design/icons';
import QueueAnim from 'rc-queue-anim'; //antd提供的动画库

//'../../assets/ruffle/ruffle.js';

//import Ruffle from './ruffle.js';
//import Flash from '../Flash';
import Loading from '../Loading';
//const Login = lazy(()=>import('../../pages/Login'));
//const Register = lazy(()=>import('../../pages/Register'));

//console.log(Ruffle);
//Ruffle.newest();

import PubSub from 'pubsub-js';

const Flash = lazy(() => import('../Flash'));

const { Header, Content, Footer } = Layout;

let timerForQueue = null;

let timer = null;
class NoLogin extends Component {
    /*
    constructor(props){
        super(props);
    }
    */
    constructor(props) {
        super(props);
        //this.flashDOM = createRef();
        //this.contentDOM = createRef();
        //this.footerDOM = createRef();

        let pathname = props?.history?.location?.pathname;
        //console.log('NoLogin里面',props.directives);
        if (
            ('' + pathname).includes('/manage') &&
            props.directives !== 'manaul'
        ) {
            message.warning('状态超时，请重新登录系统');
        }
        //console.log('组件加载时',props);
    }
    _isMounted = false;
    state = {
        nav: [
            {
                name: 'login',
                text: '登录',
                url: '/login',
                icon: <KeyOutlined></KeyOutlined>,
                isNormalLink: false,
            },
            {
                name: 'register',
                text: '注册',
                url: '/register',
                icon: <UserAddOutlined></UserAddOutlined>,
                isNormalLink: false,
            },
            {
                name: 'home',
                text: '返回主页',
                url: '//www.sheaneh.com/',
                icon: <HomeOutlined></HomeOutlined>,
                isNormalLink: true,
            },
        ],
        navActive: '',
        isFlashLoaded: !!window.__isPageActive, //false,
        swfLink: [
            /*
            '/static/flash/ktr.swf',
            '/static/flash/qhc.swf',
            */
            //'/static/flash/chibang.swf',
            //'/static/flash/ylzg.swf',
            //'/static/flash/naxiehuaer.swf',
            //'/static/flash/lo.swf',
            //'/admin/sources/flash/dim.swf',
            '/admin/sources/flash/xiane.swf',
            '/admin/sources/flash/piaoxue.swf',
            '/admin/sources/flash/tongzhuo.swf',
            //'/admin/sources/flash/tcxx_cat_war.swf',
            //'/admin/sources/flash/tcxx_stars.swf',
            //'/admin/sources/flash/tcxx_cards.swf',
            '/admin/sources/flash/ylzg.swf',
            '/admin/sources/flash/naxiehuaer.swf',
            '/admin/sources/flash/lo.swf',
            '/admin/sources/flash/chibang.swf',
            //点击链接加入群聊【网页设计交流】：https://jq.qq.com/?_wv=1027&k=KzZ7A0NF
        ],
        swfRandNum: 0,
        flexReverse: false,
        transitionFade: false,
        cssContentCompatIE: {},
        styles: {
            headerHeight: 55,
            footerHeight: 72,
        },
        inputData: {},
    };
    inputData = {
        email: '',
        username: '',
    };
    handleFlexReverse = toggle => event => {
        this.setState({
            flexReverse: !!toggle,
        });
    };
    handleNavActive = key => event => {
        let navActive;
        if (event && event.key) {
            navActive = '' + event.key;
        } else {
            navActive = '' + this.getFirstName(key);
        }
        if (navActive !== this.state.navActive) {
            this.setState({ navActive });
        }
    };
    getFirstName = pathname => {
        return ('' + pathname).replace(/^\/(.*)?(\/)?/, '$1');
    };
    /*
    componentWithProps = Wrap => event => {
        const props = {
            flexReserve:this.handleFlexReverse
        }
        return (
            <Wrap {...props}></Wrap>
        )
    }
    */
    clearObject = object => {
        let k;
        for (k in object) {
            object[k] = '';
        }
        return object;
    };
    handleInputData = data =>
        data === '@clear'
            ? this.clearObject(this.inputData)
            : Object.assign(this.inputData, data);
    getInputData = () => this.inputData;
    componentAddProps = Wrap => () =>
        (
            <Wrap
                {...this.props}
                getInputData={this.getInputData}
                handleInputData={this.handleInputData}
            />
        );
    componentDidMount() {
        this._isMounted = true;
        //路由监听模块
        //console.log('NoLogin组件的props',this.props);
        if (this.props.history) {
            this.props.history.listen(route => {
                if (!this._isMounted) {
                    return;
                }
                this.handleFlexReverse(
                    /^\/register/.test(
                        route.pathname
                    ) /*!!route.pathname.includes('register')*/
                )();
            });
            this.handleFlexReverse(
                /^\/register/.test(
                    this.props.history.location.pathname
                ) /*!!route.pathname.includes('register')*/
            )();
            try {
                this.handleNavActive(this.props.history.location.pathname)();
            } catch (e) {}
        }
        /*
        if(this.props?.history?.location?.pathname){
            if(/^\/register/.test(this.props.history.location.pathname)){
                this.handleFlexReverse(true)();
            }
        }
        */

        //flash动画模块
        let { swfRandNum, swfLink } = this.state;
        swfRandNum = Math.floor(Math.random() * swfLink.length);
        this.setState({ swfRandNum });

        /*
        let flashPlayer;
        Ruffle.then(player => {
            flashPlayer = player.newest().createPlayer();
            //console.log(flashPlayer);
            this.flashDOM.innerHTML = '';
            this.flashDOM.appendChild(flashPlayer);
            flashPlayer.load('/static/flash/qhc.swf').then().catch();

        }).catch(err=>console.error(err));
        */
        PubSub.publish('isNoLoginAuto', true);

        //针对不兼容的浏览器处理Flash播放器监听事件
        const mousedownFn = () => {
            if (this.state.isFlashLoaded) {
                return;
            }
            window.__isPageActive = true;
            this.setState({ isFlashLoaded: true });
            window.removeEventListener('mousedown', mousedownFn);
            //window.removeEventListener('keydown',mousedownFn);
            return;
        };
        window.addEventListener('mousedown', mousedownFn);
        //window.addEventListener('keydown',mousedownFn);
        //处理IE11兼容性问题
        if (/Trident/.test(navigator.userAgent)) {
            (() => {
                const { styles } = this.state;
                const compatEventFunction = () => {
                    const cssContentCompatIE = {
                        height: (() => {
                            //console.log(this.footerDOM);
                            return document.documentElement.offsetHeight <
                                648 + styles.headerHeight + styles.footerHeight
                                ? 'auto'
                                : document.documentElement.offsetHeight -
                                      styles.footerHeight +
                                      'px';
                        })(),
                    };
                    this.setState({ cssContentCompatIE });
                };
                compatEventFunction();
                window.addEventListener('resize', compatEventFunction);
            })();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!this._isMounted) {
            return;
        }
        const { state } = this;
        if (prevState.flexReverse !== state.flexReverse) {
            clearTimeout(timer);
            //console.log('将transitionFade设为false');
            this.setState({
                transitionFade: false, //!this.state.transitionFade
            });
            timer = setTimeout(() => {
                //console.log('将transitionFade设为true');
                this.setState({
                    transitionFade: true, //!this.state.transitionFade
                });
            }, 500);
        }
        //导航栏
        try {
            this.handleNavActive(this.props.history.location.pathname)();
        } catch (e) {}
    }
    componentWillUnmount() {
        this._isMounted = false;
        clearTimeout(timer);
    }
    render() {
        const {
            nav,
            navActive,
            swfLink,
            swfRandNum,
            flexReverse,
            transitionFade,
            cssContentCompatIE,
            styles,
            isFlashLoaded,
        } = this.state;
        //console.log(navActive);
        return (
            <Layout className={css.layout}>
                <Header
                    className={css.header}
                    style={{ height: styles.headerHeight + 'px' }}
                >
                    <div className={css['header-logo-area']}>
                        <NavLink to='/'>
                            <Logo
                                innerStyle={{ margin: 0, padding: 0 }}
                                width={45}
                                height={45}
                                showtitle={true}
                                showdesc={false}
                            ></Logo>
                        </NavLink>
                    </div>
                    <div className={css['header-menu-area']}>
                        <Menu
                            onClick={this.handleNavActive()}
                            selectedKeys={[navActive]}
                            mode='horizontal'
                            style={{ backgroundColor: 'transparent' }}
                        >
                            {nav.map(item => (
                                <Menu.Item key={item.name} icon={item.icon}>
                                    {item.isNormalLink ? (
                                        <a
                                            href={item.url}
                                            target='_blank'
                                            data-sheaneh-link
                                            data-key={item.name}
                                        >
                                            {item.text}
                                        </a>
                                    ) : (
                                        <NavLink key={item.name} to={item.url}>
                                            {item.text}
                                        </NavLink>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                    {/*nav.map(item=>(
                        <NavLink key={item.name} to={item.url}>{item.text}</NavLink>
                    ))*/}
                </Header>
                <Content className={css.content} style={cssContentCompatIE}>
                    <div
                        className={css.container}
                        style={{
                            flexDirection: flexReverse ? 'row-reverse' : 'row',
                        }}
                    >
                        {/**
                         * 
                         * 
                        <div ref={ele => this.flashDOM = ele} className={css['flash-area']}>
                            <span>这里放flash动画</span>
                        </div>
                        */}
                        <div className={css['flash-area']}>
                            {isFlashLoaded ? (
                                <>
                                    <Suspense
                                        fallback={
                                            <Loading msg='正在初始化Flash模块' />
                                        }
                                    >
                                        <Flash
                                            width='100%'
                                            height='100%'
                                            src={swfLink[swfRandNum]}
                                        ></Flash>
                                    </Suspense>
                                </>
                            ) : (
                                <>
                                    <div
                                        onMouseDown={() =>
                                            this.setState({
                                                isFlashLoaded: true,
                                            })
                                        }
                                        className={css['flash-unload-area']}
                                    >
                                        <div
                                            className={
                                                css['flash-unload-area-icon']
                                            }
                                        >
                                            Flash
                                        </div>
                                        <div
                                            className={
                                                css['flash-unload-area-text']
                                            }
                                        >
                                            点击此处激活Flash动画
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={css['login-area']}>
                            {/*
                            <TransitionGroup 
                                data-transition 
                                in={'nlp'}
                            >
                                <CSSTransition
                                    in={transitionFade}
                                    timeout={{
                                        enter:1000,
                                        leave:100
                                    }}
                                    classNames="nlp"
                                    unmountOnExit
                                >
                                */}
                            {/*
                                <ReactCSSTransitionGroup
                                    transitionName='a'
                                    transitionEnterTimeout={500}
                                    transitionLeaveTimeout={300}
                                    tag="div"
                                >*/}
                            <QueueAnim
                                data-queue-anim-nologin
                                delay={0}
                                animConfig={[
                                    {
                                        opacity: [1, 0],
                                        translateX: [0, flexReverse ? 30 : -30],
                                        grayscale: [0, 0.3],
                                    },
                                ]}
                                duration={[300, 0]}
                                animatingClassName={[
                                    css['area-animation-entering'],
                                    css['area-animation-leaving'],
                                ]}
                                className={[
                                    css['quene-anim-area'],
                                    css['login-area-inner'],
                                ].join(' ')}
                                onEnd={event => {
                                    clearTimeout(timerForQueue);
                                    //console.log(document.querySelector('[data-queue-anim-nologin]').childNodes.length);
                                    if (
                                        document.querySelector(
                                            '[data-queue-anim-nologin]'
                                        ).childNodes.length >= 2
                                    ) {
                                        timerForQueue = setTimeout(function () {
                                            if (
                                                typeof timerForQueue ===
                                                'number'
                                            ) {
                                                message.error('页面渲染错误');
                                                window.location.reload();
                                            }
                                        }, 500);
                                    }
                                }}
                            >
                                <div
                                    data-queue-anim-nologin-in
                                    key={this.props.history.location.pathname}
                                >
                                    <Switch>
                                        {/**
                                         * <Suspense fallback={(<h1>loading......</h1>)}>
                                         */}
                                        <Route
                                            path='/register'
                                            component={this.componentAddProps(
                                                Register
                                            )}
                                        ></Route>
                                        <Route
                                            path='/login'
                                            component={this.componentAddProps(
                                                Login
                                            )}
                                        ></Route>
                                        <Route
                                            path='/forget'
                                            component={this.componentAddProps(
                                                Forget
                                            )}
                                        ></Route>
                                        <Redirect
                                            to={`/login?from=undefined&now=${new Date().getTime()}`}
                                        ></Redirect>
                                        {/*
                                            <Route path='/register' component={()=><div key={Math.random()}>这是注册组件</div>}></Route>
                                            <Route path='/login' component={()=><div key={Math.random()}>这是登录组件</div>}></Route>
                                            <Redirect to={`/login?from=undefined&now=${new Date().getTime()}`}></Redirect>
                                            */}
                                    </Switch>
                                </div>
                            </QueueAnim>
                            {/*</ReactCSSTransitionGroup>*/}
                            {/*}
                                </CSSTransition>
                            </TransitionGroup>
                            */}
                        </div>
                        {/*
                                <img width="100%" src={ImageHead} style={{marginBottom:'24px'}} alt="被你们一口一个" />
                            */}
                        {/**
                             * 
                            <Row gutter={[8,0]}>
                                <Col span={1}>
                                    <Button type="default">注册账号</Button>
                                </Col>
                                <Col span={1}>
                                    <Button type="default">验证码登录</Button>
                                </Col>
                                <Col span={1}>
                                    <Button type="default">二维码登录</Button>
                                </Col>
                            </Row>
                            * 
                            * 
                            * 
                            * 
                            * 
                            */}
                        {/*
                        <div className={css.container}>
                            <div className={css.wrap}>
                                <form action="/" method="post">
                                    <div>
                                        <label htmlFor="username">
                                            <span>用户名</span>
                                            <input id="username" name="username" type="text" defaultValue="admin" />
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="password">
                                            <span>密码</span>
                                            <input id="password" name="password" type="text" defaultValue="" />
                                        </label>
                                    </div>
                                    <div>
                                        <label htmlFor="loginaction">
                                            <input id="loginaction" type="submit" value="登录"/>
                                        </label>
                                        
                                    </div>
                                </form>
                            </div>
                            <Button type="primary">你好</Button>
                        </div>
                        */}
                        {/***
                         * 
                         * 
                        <CSSTransition
                            in={!flexReverse}
                            out={flexReverse}
                            timeout={1000}
                            classNames="nlp"
                        >
                            <div>1111</div>
                        </CSSTransition>
                        * 
                        */}
                    </div>
                </Content>
                <Footer
                    className={css.footer}
                    style={{ maxHeight: styles.footerHeight + 'px' }}
                >
                    <div>仙娥小站后台管理中心</div>
                    <div>
                        Copyright © 2021 仙娥小站 [sheaneh.com] All Rights
                        Reserved
                    </div>
                </Footer>
            </Layout>
        );
    }
}

export default withRouter(NoLogin);
