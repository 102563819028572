export const Ball = () => (
    <svg width="57" height="57" viewBox="0 0 57 57" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
                <circle cx="5" cy="50" r="5">
                    <animate attributeName="cy"
                        begin="0s" dur="2.2s"
                        values="50;5;50;50"
                        calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="cx"
                        begin="0s" dur="2.2s"
                        values="5;27;49;5"
                        calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="27" cy="5" r="5">
                    <animate attributeName="cy"
                        begin="0s" dur="2.2s"
                        from="5" to="5"
                        values="5;50;50;5"
                        calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="cx"
                        begin="0s" dur="2.2s"
                        from="27" to="27"
                        values="27;49;5;27"
                        calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="49" cy="50" r="5">
                    <animate attributeName="cy"
                        begin="0s" dur="2.2s"
                        values="50;50;5;50"
                        calcMode="linear"
                        repeatCount="indefinite" />
                    <animate attributeName="cx"
                        from="49" to="49"
                        begin="0s" dur="2.2s"
                        values="49;5;27;49"
                        calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
            </g>
        </g>
    </svg>
)
export const Bars = () => (
    <svg width="135" height="140" viewBox="0 0 135 140" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <rect y="10" width="15" height="120" rx="6">
            <animate attributeName="height"
                begin="0.5s" dur="1s"
                values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="y"
                begin="0.5s" dur="1s"
                values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                repeatCount="indefinite" />
        </rect>
        <rect x="30" y="10" width="15" height="120" rx="6">
            <animate attributeName="height"
                begin="0.25s" dur="1s"
                values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="y"
                begin="0.25s" dur="1s"
                values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                repeatCount="indefinite" />
        </rect>
        <rect x="60" width="15" height="140" rx="6">
            <animate attributeName="height"
                begin="0s" dur="1s"
                values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="y"
                begin="0s" dur="1s"
                values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                repeatCount="indefinite" />
        </rect>
        <rect x="90" y="10" width="15" height="120" rx="6">
            <animate attributeName="height"
                begin="0.25s" dur="1s"
                values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="y"
                begin="0.25s" dur="1s"
                values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                repeatCount="indefinite" />
        </rect>
        <rect x="120" y="10" width="15" height="120" rx="6">
            <animate attributeName="height"
                begin="0.5s" dur="1s"
                values="120;110;100;90;80;70;60;50;40;140;120" calcMode="linear"
                repeatCount="indefinite" />
            <animate attributeName="y"
                begin="0.5s" dur="1s"
                values="10;15;20;25;30;35;40;45;50;0;10" calcMode="linear"
                repeatCount="indefinite" />
        </rect>
    </svg>
)
export const Circles = () => (
    <svg width="135" height="135" viewBox="0 0 135 135" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <path d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 67 67"
                to="-360 67 67"
                dur="2.5s"
                repeatCount="indefinite"/>
        </path>
        <path d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z">
            <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 67 67"
                to="360 67 67"
                dur="8s"
                repeatCount="indefinite"/>
        </path>
    </svg>
)
export const Grid = () => (
    <svg width="105" height="105" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <circle cx="12.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="0s" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
            <animate attributeName="fill-opacity"
            begin="100ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="52.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="300ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="52.5" cy="52.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="600ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="92.5" cy="12.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="800ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="92.5" cy="52.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="400ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="12.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="700ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="52.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="500ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
        <circle cx="92.5" cy="92.5" r="12.5">
            <animate attributeName="fill-opacity"
            begin="200ms" dur="1s"
            values="1;.2;1" calcMode="linear"
            repeatCount="indefinite" />
        </circle>
    </svg>
)
export const Hearts = () => (
    <svg width="140" height="64" viewBox="0 0 140 64" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <path d="M30.262 57.02L7.195 40.723c-5.84-3.976-7.56-12.06-3.842-18.063 3.715-6 11.467-7.65 17.306-3.68l4.52 3.76 2.6-5.274c3.717-6.002 11.47-7.65 17.305-3.68 5.84 3.97 7.56 12.054 3.842 18.062L34.49 56.118c-.897 1.512-2.793 1.915-4.228.9z" fill-opacity=".5">
            <animate attributeName="fill-opacity"
                begin="0s" dur="1.4s"
                values="0.5;1;0.5"
                calcMode="linear"
                repeatCount="indefinite" />
        </path>
        <path d="M105.512 56.12l-14.44-24.272c-3.716-6.008-1.996-14.093 3.843-18.062 5.835-3.97 13.588-2.322 17.306 3.68l2.6 5.274 4.52-3.76c5.84-3.97 13.592-2.32 17.307 3.68 3.718 6.003 1.998 14.088-3.842 18.064L109.74 57.02c-1.434 1.014-3.33.61-4.228-.9z" fill-opacity=".5">
            <animate attributeName="fill-opacity"
                begin="0.7s" dur="1.4s"
                values="0.5;1;0.5"
                calcMode="linear"
                repeatCount="indefinite" />
        </path>
        <path d="M67.408 57.834l-23.01-24.98c-5.864-6.15-5.864-16.108 0-22.248 5.86-6.14 15.37-6.14 21.234 0L70 16.168l4.368-5.562c5.863-6.14 15.375-6.14 21.235 0 5.863 6.14 5.863 16.098 0 22.247l-23.007 24.98c-1.43 1.556-3.757 1.556-5.188 0z" />
    </svg>
)
export const Rings = () => (
    <svg width="45" height="45" viewBox="0 0 45 45" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
        <g fill="none" fill-rule="evenodd" transform="translate(1 1)" stroke-width="2">
            <circle cx="22" cy="22" r="6" stroke-opacity="0">
                <animate attributeName="r"
                    begin="1.5s" dur="3s"
                    values="6;22"
                    calcMode="linear"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-opacity"
                    begin="1.5s" dur="3s"
                    values="1;0" calcMode="linear"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-width"
                    begin="1.5s" dur="3s"
                    values="2;0" calcMode="linear"
                    repeatCount="indefinite" />
            </circle>
            <circle cx="22" cy="22" r="6" stroke-opacity="0">
                <animate attributeName="r"
                    begin="3s" dur="3s"
                    values="6;22"
                    calcMode="linear"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-opacity"
                    begin="3s" dur="3s"
                    values="1;0" calcMode="linear"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-width"
                    begin="3s" dur="3s"
                    values="2;0" calcMode="linear"
                    repeatCount="indefinite" />
            </circle>
            <circle cx="22" cy="22" r="8">
                <animate attributeName="r"
                    begin="0s" dur="1.5s"
                    values="6;1;2;3;4;5;6"
                    calcMode="linear"
                    repeatCount="indefinite" />
            </circle>
        </g>
    </svg>
)
export const SpinCircles = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(2 1)" stroke="#FFF" stroke-width="1.5">
                <circle cx="42.601" cy="11.462" r="5" fill-opacity="1" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="1;0;0;0;0;0;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="49.063" cy="27.063" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;1;0;0;0;0;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="42.601" cy="42.663" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;1;0;0;0;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="27" cy="49.125" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;0;1;0;0;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="11.399" cy="42.663" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;0;0;1;0;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="4.938" cy="27.063" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;0;0;0;1;0;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="11.399" cy="11.462" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;0;0;0;0;1;0" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
                <circle cx="27" cy="5" r="5" fill-opacity="0" fill="#fff">
                    <animate attributeName="fill-opacity"
                        begin="0s" dur="1.3s"
                        values="0;0;0;0;0;0;0;1" calcMode="linear"
                        repeatCount="indefinite" />
                </circle>
            </g>
        </g>
    </svg>    
)
export const Tail = () => (
    <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                <stop stop-color="#fff" stop-opacity="0" offset="0%"/>
                <stop stop-color="#fff" stop-opacity=".631" offset="63.146%"/>
                <stop stop-color="#fff" offset="100%"/>
            </linearGradient>
        </defs>
        <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)">
                <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" stroke-width="2">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite" />
                </path>
                <circle fill="#fff" cx="36" cy="18" r="1">
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 18 18"
                        to="360 18 18"
                        dur="0.9s"
                        repeatCount="indefinite" />
                </circle>
            </g>
        </g>
    </svg>
)
export const Three = () => (
    <svg width="120" height="30" viewBox="0 0 120 30" xmlns="http://www.w3.org/2000/svg" fill="#fff">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fill-opacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
)