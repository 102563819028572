
export const introduce = {
    title:'仙娥小站后台管理中心',
    url:'https://sheaneh.com'
}

export const consoleDeterOutput = {
    have:[
        'New Ruffle instance created',
        'Loading SWF',
        'Ruffle instance destroyed',
    ]
}

const $output = {
    introduce,
    consoleDeterOutput
}

export default $output;