import React,{Component} from 'react';
import App from './components/App';
//import Test from './components/Test/index.jsx';
import {BrowserRouter} from 'react-router-dom';
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import {ConfigProvider,Empty,message} from 'antd';


export default class Root extends Component{
    constructor(props){
        super(props);
        ConfigProvider.config(this.state.config);
        message.config({
            top:65,
            duration:3.3,
            maxCount:3
        });
    }
    state = {
        config:{
            locale:zh_CN,
            prefixCls:'sh',
            direction:'ltr',
            virtual:true,
            renderEmpty:() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,//默认空组件
            autoInsertSpaceInButton:true,//两个汉字之间留有空格
            componentSize:'middle',
            csp:{nonce: `default-src 'self'`},// Content Security Policy 
            dropdownMatchSelectWidth:false,//下拉菜单和选择器同宽。默认将设置 min-width，当值小于选择框宽度时会被忽略。false 时会关闭虚拟滚动
            getPopupContainer:() => document.body,
            getTargetContainer:() => window,
            input:{
                autoComplete:'false'
            },
            space:{
                size:'middle'
            },
        }
    }
    render(){
        const {config} = this.state;
        //return <Test/>
        return (
            <React.Fragment>{/*Fragment  StrictMode*/}
                <BrowserRouter basename="/admin">
                    <ConfigProvider {...config}>
                        <App/>
                    </ConfigProvider>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}