import {message} from 'antd';

const data = [
    {
        name:'悠悠博客',
        type:'url',
        value:'https://www.usuuu.com',
        warn:'敢动我悠悠女神游大漂亮的博客？？？'
    },
    {
        name:'雨后池塘',
        type:'url',
        value:'https://www.yuhou.com',
    },
    {
        name:'编辑器',
        type:'url',
        value:'https://www.yuque.com/jacob.lcs/slatejs',
    },
    {
        name:'图表',
        type:'url',
        value:'https://api.highcharts.com.cn/highcharts',
    },
];

const targetLink = () => {
    if(window.__redefine){return;}
    window.__redefine = true;
    data.forEach(item => {
        Object.defineProperty(window,item.name,{
            get(){
                switch(item.type){
                    case 'url':
                        window.open(item.value);
                        break;
                    case '':
                        //
                        break;
                    default:
                        message.info(item.value);
                }
                
                return item.value;
            },
            set(){
                message.warning(item.warn || '操作失败');
            }
        })
});
}

export default targetLink;